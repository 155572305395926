<template>
  <v-app>
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
    />

    <TextBox
      class="mt-6"
      :cefrLevel="payload.cefr_level"
      v-model="rubric"
      label="Rubric"
    />

    <label class="item-label mt-6">Speaking Text</label>
    <Textarea
      class="textarea"
      :rows="4"
      :cefrLevel="payload.cefr_level"
      v-model="speakingText"
    />

    <label class="item-label">Assessment Video</label>
    <div v-if="videoFileLocation">
      <video controls width="710">
        <source :src="videoFileLocation" type="video/mp4">
      </video>
    </div>
    <div class="d-flex justify-space-between align-center">
      <label class="es-btn small">
        Upload a new video file
        <input
          class="d-none"
          type="file"
          accept=".mp4, video/mp4"
          @change="upload($event, 'video')"
        />
        <validation-provider rules="required">
          <input type="text" v-model="videoFileLocation" class="d-none" />
        </validation-provider>
      </label>
      <v-chip
        outlined
        v-if="videoFileLocation"
        :title="videoFileLocation"
        :href="videoFileLocation"
        target="_blank">
        📽️ Open video in new tab
      </v-chip>
    </div>

    <label class="item-label mt-6">Assessment Image</label>

    <div v-if="imageFileLocation">
      <img
        width="300"
        :src="imageFileLocation"
        alt="Assessment Image" />
    </div>
    <div>
      <label class="es-btn small">
        Upload a new image file
        <input
          class="d-none"
          type="file"
          accept=".svg, image/svg+xml"
          @change="upload($event, 'image')"
        />
        <validation-provider rules="required">
          <input type="text" v-model="imageFileLocation" class="d-none" />
        </validation-provider>
      </label>
    </div>

    <v-divider class="mt-7"></v-divider>

    <label class="item-label mt-5">Peer Grading Image</label>

    <div v-if="gradingImageFileLocation">
      <img
        width="300"
        :src="gradingImageFileLocation"
        alt="Peer Grading Image" />
    </div>
    <div>
      <label class="es-btn small">
        Upload a new image file
        <input
          class="d-none"
          type="file"
          accept=".svg, image/svg+xml"
          @change="upload($event, filetype='gradingImage')"
        />
        <validation-provider rules="required">
          <input type="text" v-model="gradingImageFileLocation" class="d-none" />
        </validation-provider>
      </label>
    </div>
  </v-app>
</template>

<script>
/**
 * @todo Merge this template with the GuidedTaskTextual template,
 * as they're identical except for a small change in the questions array.
 */
import { mapState, mapActions, mapGetters } from "vuex";
import TextBox from "@/components/item/TextBox";
import Textarea from "@/components/item/Textarea";
import { v4 as uuidv4 } from "uuid";
export default {
  data: () => ({
    title: "",
    rubric: "",
    speakingText: "",
    static_text: "Answer the speaker's question",

    // Note: Video and image files are in the data.assets{} payload
    videoFilename: "",
    videoFileLocation: "",
    imageFilename: "",
    imageFileLocation: "",

    // Note: Grading image is in the data.questions[] payload
    gradingImageFilename: "",
    gradingImageFileLocation: "",
  }),
  computed: {
    ...mapState("item", ["payload", "isUpdate"]),
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    async upload(event, filetype) {
      const formData = new FormData();
      formData.append("author_id", this.user.user_id);
      formData.append("file", event.target.files[0]);
      formData.append(
        "content_type",
        filetype === 'video' ? "video/mp4" : "image/svg+xml"
      );

      try {
        const response = await this.uploadFile(formData);
        const { file_name, file_location } = response.data;
        if (filetype === 'video') {
          this.videoFilename = file_name;
          this.videoFileLocation = file_location;
        }
        if (filetype === 'image') {
          this.imageFilename = file_name;
          this.imageFileLocation = file_location;
        }
        if (filetype === 'gradingImage') {
          this.gradingImageFilename = file_name;
          this.gradingImageFileLocation = file_location;
        }
      } catch (error) {
        console.error(error);
      }
    },
    onSubmit() {
      const data = {
        template_type: "GUIDED_TASK_VISUAL",
        static_text: this.static_text,
        title: this.title,
        rubric: this.rubric,
        speaking_text: this.speakingText,
      }

      data.assets = {
        videos: [{
          file_name: this.videoFilename,
          file_location: this.videoFileLocation,
        }],
        images: [{
          file_name: this.imageFilename,
          file_location: this.imageFileLocation,
        }],
      }

      if (this.isUpdate) {
        // The questions are static, except for the grading question image asset.
        // Include a minimum of data when updating.
        data.questions = [{
          question_id: this.peerGradingQuestion.question_id,
          assets: {
            images: [{
              file_name: this.gradingImageFilename,
              file_location: this.gradingImageFileLocation,
            }]
          }
        }]
      }
      else {
        /**
         * The questions[] array here is *partially* hard-coded because
         *  a) it is designed to be static, except for the peer grading image asset;
         *  b) the API requires it.
         */
        data.questions = [
          {
            question_id: uuidv4(),
            question: this.static_text,
            solution: [],
            metadatas: {"instructions": ["Hold and record your answer"]}
          },
          {
            question_id: uuidv4(),
            question: "See Asset",
            question_template: "QUESTION_WITH_ASSET",
            data_point: "Q_CONTEXTUAL_BINARY",
            position: 0,
            solution: [],
            metadatas: {"instructions": ["Hold and record your answer"]},
            assets: {
              images: [{
                file_name: this.gradingImageFilename,
                file_location: this.gradingImageFileLocation,
              }]
            }
          },
          {
            question_id: uuidv4(),
            question: "How clear were they?",
            question_template: "STAR_RATING",
            data_point: "RATING_CLARITY_5STAR",
            position: 1,
            solution: [],
            metadatas: {"instructions": ["Hold and record your answer"]}
          },
          {
            question_id: uuidv4(),
            question: "Finally, how well did they do?",
            question_template: "STAR_RATING",
            data_point: "RATING_OVERALL_5STAR",
            position: 2,
            solution: [],
            metadatas: {"instructions": ["Hold and record your answer"]}
          },
        ]
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title, rubric, speaking_text: speakingText } = this.payload
      this.title = title;
      this.rubric = rubric;
      this.speakingText = speakingText;

      // Assessment video
      const { file_location: videoFileLocation, file_name: videoFilename } = this.payload.assets.videos[0];
      this.videoFileLocation = videoFileLocation;
      this.videoFilename = videoFilename;

      // Assessment image
      const { file_location: imageFileLocation, file_name: imageFilename } = this.payload.assets.images[0];
      this.imageFileLocation = imageFileLocation;
      this.imageFilename = imageFilename;

      // Grading image
      this.peerGradingQuestion = this.payload.questions.find(({ question_template }) => question_template === "QUESTION_WITH_ASSET");
      const { file_location: gradingImageFileLocation, file_name: gradingImageFilename } = this.peerGradingQuestion.assets.images[0];
      this.gradingImageFileLocation = gradingImageFileLocation;
      this.gradingImageFilename = gradingImageFilename;
    }
  },
  components: {
    TextBox,
    Textarea,
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}
.paragraphs {
  max-width: 500pt;
  box-sizing: border-box;
  .paragraph {
    display: flex;
    margin-bottom: 15pt;
    .number {
      position: relative;
      background: #393b71;
      display: block;
      height: 21pt;
      width: 21pt;
      border-radius: 21pt;
      text-align: center;
      color: white;
      margin-top: 13pt;
      margin-right: 10pt;
    }
    .textarea {
      width: 100%;
    }
  }
}
label {
  color: #264d98;
  font-size: 1em;
  margin-bottom: 10pt 0pt;
}
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
